import * as Yup from 'yup'

import '../../../../utils/yup/yup.locale.pt-br'

const schema = Yup.object().shape({
  id: Yup.number().optional(),
  versao: Yup.string().required(),
  slug: Yup.string().required()
})

const schemaCreate = Yup.object().shape({
  sistemaid: Yup.string().nullable().required('Sistema é um campo obrigatório'),
  versao: Yup.string().required(),
  slug: Yup.string().required()
})

export const validateForm = async (data: any, create: boolean) => {
  await (create ? schemaCreate : schema).validate(data, {
    abortEarly: false
  })
}
