import styled, { css } from 'styled-components'

export const VersoesSearch = styled.div`
  flex: 1;
  display: flex;

  button {
    margin: 0.5rem;
    svg {
      font-size: 1.8rem;
    }
  }

  ${({ theme }) => css`
    ${theme.media.desktop} {
    }

    ${theme.media.tablet} {
    }

    ${theme.media.phone} {
      flex-direction: column;
    }
  `}
`
