import React, { useCallback, useEffect, useState } from 'react'
import { FaPlusCircle } from 'react-icons/fa'
import { FormRow } from '../../styles/form'

import { Button } from '../Button'
import InputSelectAsync from '../InputSelectAsync'

type OptionsSelect = {
  label: string
  value: string | number
}

interface IInputSelectAsyncMultiplo {
  label: string
  name: string
  minCount?: number
  asyncLoadOptions: (inputValue?: string) => Promise<OptionsSelect[] | null>
}

const InputSelectAsyncMultiplo: React.FC<IInputSelectAsyncMultiplo> = ({
  label,
  name,
  asyncLoadOptions,
  minCount
}) => {
  const [count, setCount] = useState<Array<{ id: string }>>([])

  const handleAdd = useCallback(() => {
    setCount([...count, { id: (+new Date()).toString(36) }])
  }, [count])

  useEffect(() => {
    if (minCount) {
      const initialData = Object.keys([...Array(minCount)]).map((value) => {
        return { id: (+new Date()).toString(36) }
      })

      setCount(initialData)
    } else setCount([])
  }, [minCount])

  return (
    <>
      {count.map((value, index) => (
        <FormRow key={value.id}>
          <InputSelectAsync
            label={`${label} ${index + 1}`}
            name={`${name}[${index}]`}
            isSearchable={true}
            asyncLoadOptions={asyncLoadOptions}
          />
        </FormRow>
      ))}
      <div className="buttonRight">
        <Button type="button" onClick={() => handleAdd()} variant="secondary">
          <FaPlusCircle />
        </Button>
      </div>
    </>
  )
}

export default InputSelectAsyncMultiplo
