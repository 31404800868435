export const Roles = {
  ADMIN: 'ADMIN',
  DEV: '2',
  SUPORTE: '3'
}

export type RolesTypes = (keyof typeof Roles)[]
export type RoleType = keyof typeof Roles

export const getRole = (value: string): RoleType => {
  return value as RoleType
  // const keys = Object.keys(Roles) as Array<keyof typeof Roles>
  // const index = Object.values(Roles).findIndex((role) => role === value)
  // return keys[index]
}
