import React, { useEffect, useRef } from 'react'
import { SubmitHandler, FormHandles } from '@unform/core'

import { Button } from '../../../../components/Button'
import Input from '../../../../components/Input'
import { Form, FormRow } from '../../../../styles/form'

import { yupGetErrors } from '../../../../utils/yup/yup.get.errors'
import { validateForm } from './schema'
import { IFormCallback } from '../../../../forms/interfaces'
import { FaTimesCircle, FaCheckCircle } from 'react-icons/fa'

import Api from '../../../../services/Api'
import { IApiSistemaData } from '../../../../services/ApiRequests/SistemaRequests/interfaces'

interface SistemaFormProps {
  sistema?: IApiSistemaData
}

const SistemaForm: React.FC<IFormCallback & SistemaFormProps> = ({
  callback,
  sistema
}) => {
  const formRef = useRef<FormHandles>(null)

  useEffect(() => {
    if (!formRef.current) return

    if (sistema && sistema.id) {
      formRef.current.setData(sistema)
    }
  }, [sistema])

  const handleSubmit: SubmitHandler = async (data, { reset }, event) => {
    if (!formRef.current) return
    event?.preventDefault()

    try {
      formRef.current.setErrors({})

      await validateForm({
        ...data,
        id: sistema?.id
      })

      if (sistema?.id) {
        await Api.sistema.update({
          ...data,
          id: sistema?.id
        })
      } else {
        await Api.sistema.save(data)
      }

      if (callback) callback()
    } catch (err) {
      const errors = yupGetErrors(err)
      formRef.current.setErrors(errors)
    }
  }

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <FormRow>
        <Input name="nome" label="Nome do Sistema" />
        <Input name="slug" label="Slug (Utilizado para o WebSetup)" />
        <Input name="redmineProjectName" label="Nome Projeto Redmine" />
      </FormRow>
      <FormRow>
        <Input name="webhookVersao" label="Webhook Versão" />
      </FormRow>
      <FormRow>
        <Input name="webhookTeste" label="Webhook Teste" />
      </FormRow>
      <FormRow buttons>
        <Button
          type="button"
          variant="danger"
          onClick={() => {
            if (callback) callback()
          }}
        >
          <FaTimesCircle />
          <span>Cancelar</span>
        </Button>

        <Button type="submit">
          <FaCheckCircle />
          <span>Salvar</span>
        </Button>
      </FormRow>
    </Form>
  )
}

export default SistemaForm
