import React, { useCallback, useEffect, useRef } from 'react'
import { SubmitHandler, FormHandles } from '@unform/core'

import { Button } from '../../../../components/Button'
import Input from '../../../../components/Input'
import { Form, FormRow } from '../../../../styles/form'

import { yupGetErrors } from '../../../../utils/yup/yup.get.errors'
import { validateFormCreate, validateFormUpdate } from './schema'
import { IFormCallback } from '../../../../forms/interfaces'
import { FaTimesCircle, FaCheckCircle } from 'react-icons/fa'

import Api from '../../../../services/Api'
import InputSelect from '../../../../components/InputSelect'
import { InputSelectOptionsSimNao } from '../../../../config/InputSelectConfig'
import InputSelectAsync from '../../../../components/InputSelectAsync'
import { InputCheckbox } from '../../../../components/InputCheckbox'
import { IApiSistemaVersaoData } from '../../../../services/ApiRequests/SistemaVersoesRequests/interfaces'

interface SistemaVersaoFormProps {
  versao?: IApiSistemaVersaoData
}

const SistemaVersoesForm: React.FC<IFormCallback & SistemaVersaoFormProps> = ({
  callback,
  versao
}) => {
  const formRef = useRef<FormHandles>(null)

  const handleSearchSistema = useCallback(async (inputValue?: string) => {
    return Api.sistema
      .get({ field: 'nome', value: inputValue })
      .then((data) => {
        const arrayData = data.results.map((sis) => ({
          label: sis.nome,
          value: sis.id
        }))
        return arrayData
      })
  }, [])

  useEffect(() => {
    if (!formRef.current) return

    if (versao && versao.id) {
      formRef.current.setData({
        ...versao,
        ativo: versao.ativo ? 'S' : 'N',
        teste: versao.teste ? 'S' : 'N',
        liberado: versao.liberado ? 'S' : 'N'
      })
    }
  }, [versao])

  const handleSubmit: SubmitHandler = async (data, { reset }, event) => {
    if (!formRef.current) return
    event?.preventDefault()

    try {
      formRef.current.setErrors({})

      const ativo = data.ativo === 'S'
      const teste = data.teste === 'S'
      const liberado = data.liberado === 'S'
      const redmineId = data.redmineId || null

      const dataForm = { ...data, ativo, teste, liberado, redmineId }

      if (versao?.id) {
        await validateFormUpdate({
          ...dataForm,
          id: versao?.id
        })
        await Api.sistemaVersoes.update({
          ...dataForm,
          id: versao?.id
        })
      } else {
        await validateFormCreate({
          ...dataForm,
          id: versao?.id
        })
        if (!dataForm.sistemaid) throw new Error('Informe o sistema!')
        await Api.sistemaVersoes.save(dataForm)
      }

      if (callback) callback()
    } catch (err) {
      const errors = yupGetErrors(err)
      formRef.current.setErrors(errors)
    }
  }

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      {!versao?.id && (
        <FormRow>
          <InputSelectAsync
            label="Sistema"
            name="sistemaid"
            placeholder="Digite aqui para pesquisar um Sistema"
            asyncLoadOptions={handleSearchSistema}
          />
        </FormRow>
      )}
      <FormRow>
        <Input name="versao" label="Versão" />
        <InputSelect
          isClearable={false}
          label="Versão de Teste?"
          name="teste"
          options={InputSelectOptionsSimNao}
        />
        <InputSelect
          isClearable={false}
          label="Liberado ?"
          name="liberado"
          options={InputSelectOptionsSimNao}
        />
        <InputSelect
          isClearable={false}
          label="Ativo ?"
          name="ativo"
          options={InputSelectOptionsSimNao}
        />
        <Input name="redmineId" label="Redmine ID" />
      </FormRow>
      <FormRow>
        <InputCheckbox name="autoupdate" label="Auto Update?" />
        <Input name="autoupdatehash" label="Auto Update Hash" />
      </FormRow>
      <FormRow buttons>
        <Button
          type="button"
          variant="danger"
          onClick={() => {
            if (callback) callback()
          }}
        >
          <FaTimesCircle />
          <span>Cancelar</span>
        </Button>

        <Button type="submit">
          <FaCheckCircle />
          <span>Salvar</span>
        </Button>
      </FormRow>
    </Form>
  )
}

export default SistemaVersoesForm
