import {
  alertClose,
  alertLoading,
  alertSucesso
} from '../../../utils/sweetAlert'
import BaseRequests from '../BaseRequests'
import {
  IApiSistemaCreate,
  IApiSistemaData,
  IApiSistemaFilter,
  IApiSistemaSearch,
  IApiSistemaUpdate,
} from './interfaces'

class SistemaRequests extends BaseRequests {
  constructor() {
    super('sistemas')
  }

  async getById(id: number): Promise<IApiSistemaData> {
    return this.sendGet<IApiSistemaData>({ url: `${id}` })
  }

  async get(search?: IApiSistemaFilter): Promise<IApiSistemaSearch> {
    return this.sendGet<IApiSistemaSearch>({ params: { ...search } })
  }

  async save(data: IApiSistemaCreate): Promise<void> {
    alertLoading('Cadastrando Sistema...')
    return this.sendPost({ data })
      .then(() => {
        alertSucesso()
      })
      .catch((err) => {
        alertClose()
        throw err
      })
  }

  async update(data: IApiSistemaUpdate): Promise<void> {
    alertLoading('Atualizando Sistema...')
    return this.sendPut({ data })
      .then(() => {
        alertSucesso()
      })
      .catch((err) => {
        alertClose()
        throw err
      })
  }

  async delete(id: number): Promise<void> {
    return this.sendDelete({ param: `${id}` })
  }

}

export default SistemaRequests
