import * as Yup from 'yup'

import '../../../../utils/yup/yup.locale.pt-br'

const schema = Yup.object().shape({
  id: Yup.number().optional(),
  versao: Yup.string().required(),
  teste: Yup.boolean().nullable().required(),
  liberado: Yup.boolean().nullable().required(),
  ativo: Yup.boolean().nullable().required(),
  autoupdate: Yup.boolean().optional()
})

const schemaCreate = Yup.object().shape({
  sistemaid: Yup.string().nullable().required('Sistema é um campo obrigatório'),
  versao: Yup.string().required(),
  teste: Yup.boolean().nullable().required(),
  liberado: Yup.boolean().nullable().required(),
  ativo: Yup.boolean().nullable().required()
})

export const validateFormUpdate = async (data: any) => {
  await schema.validate(data, {
    abortEarly: false
  })
}
export const validateFormCreate = async (data: any) => {
  await schemaCreate.validate(data, {
    abortEarly: false
  })
}
