import { addHours, format, parseISO, differenceInDays } from 'date-fns'

export const dateFormat = 'dd/MM/yyyy'
export const dateTimeFormat = 'dd/MM/yyyy HH:mm:ss'

export const formatDateTime = (
  value: string | Date,
  mask: string = dateTimeFormat
): string => {
  // utc - 4
  const formated = format(addHours(typeof value === 'string' ? parseISO(value) : value, 4), mask)
  return formated
}


export const daysBetween = (dateLeft: string | Date, dateRight: string | Date) => {
  const left = typeof dateLeft === 'string' ? parseISO(dateLeft) : dateLeft
  const right = typeof dateRight === 'string' ? parseISO(dateRight) : dateRight

  return differenceInDays(left, right)
}

