import {
  alertClose,
  alertLoading,
  alertSucesso
} from '../../../utils/sweetAlert'
import BaseRequests from '../BaseRequests'
import {
  IApiWebsetupCreate,
  IApiWebsetupData,
  IApiWebsetupUpdate
} from './interfaces'

class WebsetupRequests extends BaseRequests {
  constructor() {
    super('websetups')
  }

  async getById(id: number): Promise<IApiWebsetupData> {
    return this.sendGet<IApiWebsetupData>({ url: `${id}` })
  }

  async get(): Promise<IApiWebsetupData[]> {
    return this.sendGet<IApiWebsetupData[]>({})
  }

  async save(data: IApiWebsetupCreate): Promise<void> {
    alertLoading('Cadastrando Websetup...')
    return this.sendPost({ data })
      .then(() => {
        alertSucesso()
      })
      .catch((err) => {
        alertClose()
        throw err
      })
  }

  async update(data: IApiWebsetupUpdate): Promise<void> {
    alertLoading('Atualizando Websetup...')
    return this.sendPut({ data })
      .then(() => {
        alertSucesso()
      })
      .catch((err) => {
        alertClose()
        throw err
      })
  }

  async delete(id: number): Promise<void> {
    return this.sendDelete({ param: `${id}` })
  }

}

export default WebsetupRequests
