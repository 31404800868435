import React, { useCallback, useEffect, useRef, useState } from 'react'
import { SubmitHandler, FormHandles } from '@unform/core'

import { Button } from '../../../../components/Button'
import Input from '../../../../components/Input'
import {
  Form,
  FormGroupSeparator,
  FormRow,
  FormRowStrippedWrapper
} from '../../../../styles/form'

import { yupGetErrors } from '../../../../utils/yup/yup.get.errors'
import { validateForm } from './schema'
import { IFormCallback } from '../../../../forms/interfaces'
import { FaTimesCircle, FaCheckCircle, FaTrash } from 'react-icons/fa'

import Api from '../../../../services/Api'
import {
  IApiWebsetupData,
  IApiWebsetupModulo,
  IApiWebsetupSistema
} from '../../../../services/ApiRequests/WebSetupRequests/interfaces'
import InputSelectAsync from '../../../../components/InputSelectAsync'
import InputSelectAsyncMultiplo from '../../../../components/InputSelectAsyncMultiplo'
import { Table } from '../../../../components/Table'
import { TableButtonWrapper } from '../../../../components/Table/styles'

interface WebsetupFormProps {
  websetup?: IApiWebsetupData
}

const WebsetupForm: React.FC<IFormCallback & WebsetupFormProps> = ({
  callback,
  websetup
}) => {
  const formRef = useRef<FormHandles>(null)
  const [modules, setModules] = useState<IApiWebsetupModulo[]>([])
  const [sistemas, setSistemas] = useState<IApiWebsetupSistema[]>([])

  const handleDeleteModulo = useCallback(
    (id: number) => {
      const filtro = modules.filter((mod) => mod.id !== id)
      setModules(filtro)
    },
    [modules]
  )

  const handleDeleteSistema = useCallback(
    (id: number) => {
      const filtro = sistemas.filter((mod) => mod.id !== id)
      setSistemas(filtro)
    },
    [sistemas]
  )

  useEffect(() => {
    if (!formRef.current) return

    if (websetup && websetup.id) {
      const { modulos, sistemas, ...rest } = websetup
      setModules(modulos)
      setSistemas(sistemas)
      formRef.current.setData(rest)
    }
  }, [websetup])

  const handleSubmit: SubmitHandler = async (data, { reset }, event) => {
    if (!formRef.current) return
    event?.preventDefault()
    try {
      formRef.current.setErrors({})

      const moduleId = modules.reduce<number[]>((acc, { id }) => {
        return [...acc, id]
      }, [])

      const sistemaId = sistemas.reduce<number[]>((acc, { id }) => {
        return [...acc, id]
      }, [])

      if (data.modulos && data.modulos.length)
        data.modulos.map((mod: number | null) => {
          if (mod !== null) moduleId.push(mod)
          return mod
        })

      if (data.adicionais && data.adicionais.length)
        data.adicionais.map((mod: number | null) => {
          if (mod !== null) sistemaId.push(mod)
          return mod
        })

      const modulesFinal = [...new Set(moduleId)]
      const sistemasFinal = [...new Set(sistemaId)]

      if (websetup?.id) {
        await validateForm(
          {
            ...data,
            id: websetup?.id
          },
          false
        )

        await Api.websetup.update({
          ...data,
          id: websetup?.id,
          modulos: modulesFinal,
          adicionais: sistemasFinal
        })
      } else {
        await validateForm(data, true)
        await Api.websetup.save({
          ...data,
          modulos: modulesFinal,
          adicionais: sistemasFinal
        })
      }
      if (callback) callback()
    } catch (err) {
      const errors = yupGetErrors(err)
      formRef.current.setErrors(errors)
    }
  }

  const handleSearchSistema = useCallback(async (inputValue?: string) => {
    return Api.sistema
      .get({ field: 'nome', value: inputValue })
      .then((data) => {
        const arrayData = data.results.map((cor) => ({
          label: cor.nome,
          value: cor.id
        }))
        return arrayData
      })
  }, [])

  const handleSearchModulo = useCallback(async (inputValue?: string) => {
    return Api.websetupModulos
      .get({ field: 'nome', value: inputValue })
      .then((data) => {
        const arrayData = data.map((cor) => ({
          label: cor.nome,
          value: cor.id
        }))
        return arrayData
      })
  }, [])

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      {!websetup?.id && (
        <FormRow>
          <InputSelectAsync
            label="Sistema"
            name="sistemaid"
            placeholder="Digite aqui para pesquisar um Sistema"
            asyncLoadOptions={handleSearchSistema}
          />
        </FormRow>
      )}
      <FormRow>
        <Input name="versao" label="Versão do Websetup" />
        <Input name="slug" label="Slug (Utilizado para o WebSetup)" />
      </FormRow>

      <FormGroupSeparator>
        <span>Sistemas Adicionados</span>
        <hr />
      </FormGroupSeparator>
      {websetup?.sistemas && websetup?.sistemas.length > 0 && (
        <>
          <Table<IApiWebsetupSistema & { opcoes: string }>
            headers={{
              nome: 'Nome',
              opcoes: 'Opções'
            }}
            columnSizes={{
              opcoes: '4rem'
            }}
            items={sistemas}
            customRenderers={{
              opcoes: (obj) => (
                <TableButtonWrapper>
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={() => {
                      if (obj.id) handleDeleteSistema(obj.id)
                    }}
                  >
                    <FaTrash />
                  </Button>
                </TableButtonWrapper>
              )
            }}
          />
        </>
      )}

      <FormRowStrippedWrapper>
        <InputSelectAsyncMultiplo
          label="Sistemas"
          name="adicionais"
          asyncLoadOptions={handleSearchSistema}
        />
      </FormRowStrippedWrapper>

      <FormGroupSeparator>
        <span>Módulos Adicionados</span>
        <hr />
      </FormGroupSeparator>
      {websetup?.modulos && websetup?.modulos.length > 0 && (
        <>
          <Table<IApiWebsetupModulo & { opcoes: string }>
            headers={{
              nome: 'Nome',
              versao: 'Versão',
              opcoes: 'Opções'
            }}
            columnSizes={{
              versao: '10rem',
              opcoes: '4rem'
            }}
            items={modules}
            customRenderers={{
              opcoes: (obj) => (
                <TableButtonWrapper>
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={() => {
                      if (obj.id) handleDeleteModulo(obj.id)
                    }}
                  >
                    <FaTrash />
                  </Button>
                </TableButtonWrapper>
              )
            }}
          />
        </>
      )}

      <FormRowStrippedWrapper>
        <InputSelectAsyncMultiplo
          label="Modulos"
          name="modulos"
          asyncLoadOptions={handleSearchModulo}
        />
      </FormRowStrippedWrapper>
      <FormRow buttons>
        <Button
          type="button"
          variant="danger"
          onClick={() => {
            if (callback) callback()
          }}
        >
          <FaTimesCircle />
          <span>Cancelar</span>
        </Button>

        <Button type="submit">
          <FaCheckCircle />
          <span>Salvar</span>
        </Button>
      </FormRow>
    </Form>
  )
}

export default WebsetupForm
