import React, { useRef, useEffect, useCallback, useState } from 'react'
import { OptionTypeBase, Props as SelectProps, ValueType } from 'react-select'
import AsyncSelect from 'react-select/async'
import debounce from 'lodash.debounce'

import { useField } from '@unform/core'
import { FormGroup } from '../../styles/form'

type OptionsSelect = {
  label: string
  value: string | number
}

interface Props extends SelectProps<OptionTypeBase> {
  name: string
  label?: string
  width?: string
  asyncLoadOptions: (inputValue?: string) => Promise<OptionsSelect[] | null>
}

const InputSelectAsync: React.FC<Props> = ({
  name,
  label,
  width,
  asyncLoadOptions,
  ...rest
}) => {
  const selectRef = useRef(null)
  const [optionValue, setOptionValue] = useState<OptionTypeBase | null>()

  const { fieldName, defaultValue, registerField, error, clearError } =
    useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        return optionValue?.value ? optionValue?.value : null
      },
      setValue: (ref: any, value: OptionTypeBase) => {
        if (value) setOptionValue(value)
      },
      clearValue: (ref) => {
        setOptionValue(null)
        // ref.select.clearValue()
      }
    })
  }, [fieldName, optionValue, registerField])

  const customStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      // background: '#fff',
      // borderColor: '#9e9e9e',
      minHeight: '32px',
      height: '32px',
      boxShadow: state.isFocused ? null : null
    }),

    valueContainer: (provided: any, state: any) => ({
      ...provided,
      height: '32px',
      padding: '0 6px'
    }),

    input: (provided: any, state: any) => ({
      ...provided,
      margin: '0px'
    }),
    indicatorSeparator: (state: any) => ({
      display: 'none'
    }),
    indicatorsContainer: (provided: any, state: any) => ({
      ...provided,
      height: '32px'
    })
  }

  const handleLoadData = useCallback(
    (
      inputValue: string,
      callback: (options: ReadonlyArray<OptionTypeBase>) => void
    ) => {
      if (!!inputValue && inputValue.length >= 2) {
        asyncLoadOptions(inputValue).then((data) => {
          if (data) callback(data)
        })
      }
    },
    [asyncLoadOptions]
  )

  const delayedLoadData = debounce(handleLoadData, 500)

  const handleChange = (option: ValueType<OptionTypeBase, false>) => {
    setOptionValue(option)
  }

  return (
    <FormGroup width={width}>
      {label && <label htmlFor={fieldName}>{label}</label>}
      <div>
        <AsyncSelect
          value={optionValue}
          defaultValue={defaultValue}
          ref={selectRef}
          loadOptions={delayedLoadData}
          onChange={handleChange}
          classNamePrefix="react-select"
          placeholder="Digite para Pesquisar"
          noOptionsMessage={() => 'Sem opções'}
          styles={customStyles}
          isClearable={true}
          onFocus={clearError}
          {...rest}
        />
      </div>
      {error && <span className="error">{error}</span>}
    </FormGroup>
  )
}

export default InputSelectAsync
