import {
  alertClose,
  alertLoading,
  alertSucesso
} from '../../../utils/sweetAlert'
import BaseRequests from '../BaseRequests'
import {
  IApiSistemaVersaoCreate,
  IApiSistemaVersaoData,
  IApiSistemaVersaoUpdate
} from './interfaces'

class SistemaVersoesRequests extends BaseRequests {
  constructor() {
    super('sistema/versoes')
  }

  async getById(id: number): Promise<IApiSistemaVersaoData> {
    return this.sendGet<IApiSistemaVersaoData>({ url: `${id}` })
  }

  async get(sistema: number): Promise<IApiSistemaVersaoData[]> {
    return this.sendGet<IApiSistemaVersaoData[]>({
      params: { sistema }
    })
  }

  async save(data: IApiSistemaVersaoCreate): Promise<void> {
    alertLoading('Cadastrando Versão...')
    return this.sendPost({ data })
      .then(() => {
        alertSucesso()
      })
      .catch((err) => {
        alertClose()
        throw err
      })
  }

  async update(data: IApiSistemaVersaoUpdate): Promise<void> {
    alertLoading('Atualizando Versão...')
    return this.sendPut({ data })
      .then(() => {
        alertSucesso()
      })
      .catch((err) => {
        alertClose()
        throw err
      })
  }

  async delete(id: number): Promise<void> {
    return this.sendDelete({ param: `${id}` })
  }
}

export default SistemaVersoesRequests
