import React, { useEffect, useRef, useState } from 'react'
import { SubmitHandler, FormHandles } from '@unform/core'

import { Button } from '../../../../components/Button'
import Input from '../../../../components/Input'
import InputSelect from '../../../../components/InputSelect'
import { Form, FormRow } from '../../../../styles/form'

import { yupGetErrors } from '../../../../utils/yup/yup.get.errors'
import { validateForm } from './schema'
import { IFormCallback } from '../../../../forms/interfaces'
import { FaTimesCircle, FaCheckCircle } from 'react-icons/fa'
import {
  InputSelectOptionsPerfis,
  InputSelectOptionsSimNao
} from '../../../../config/InputSelectConfig'
import Api from '../../../../services/Api'
import { RoleType } from '../../../../config/Roles'

export interface UsuarioDataForm {
  id: number
  nome: string
  email: string
  senha?: string
  ativo: string
  tipo: string
}

interface UsuarioFormProps {
  usuario?: UsuarioDataForm
  role?: RoleType
}

const UsuarioForm: React.FC<IFormCallback & UsuarioFormProps> = ({
  callback,
  usuario,
  role = 'ADMIN'
}) => {
  const formRef = useRef<FormHandles>(null)
  const [usuarioID, setUsuarioID] = useState<number>()

  const handleSubmit: SubmitHandler = async (data, { reset }, event) => {
    if (!formRef.current) return
    event?.preventDefault()

    try {
      formRef.current.setErrors({})

      await validateForm({
        ...data,
        id: usuarioID,
        ...(role !== 'ADMIN' && { tipo: usuario?.tipo, ativo: usuario?.ativo })
      })

      if (usuarioID) {
        await Api.usuario.update({
          ...data,
          id: usuarioID,
          ...(role !== 'ADMIN' && {
            tipo: usuario?.tipo,
            ativo: usuario?.ativo
          })
        })
      } else {
        await Api.usuario.save(data)
      }

      if (callback) callback()
    } catch (err) {
      const errors = yupGetErrors(err)
      formRef.current.setErrors(errors)
    }
  }

  useEffect(() => {
    if (!formRef.current) return

    if (usuario && usuario.nome) {
      setUsuarioID(usuario.id)
      formRef.current.setData({ ...usuario, senha: '' })
    }
  }, [usuario])

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <FormRow>
        <Input name="nome" label="Nome do Usuário" />
        <Input
          name="email"
          label="E-email"
          placeholder="email@gmail.com"
          readOnly={!!usuarioID}
        />
      </FormRow>
      <FormRow>
        <Input name="senha" label="Senha" type="password" />
        {role === 'ADMIN' && (
          <>
            <InputSelect
              name="tipo"
              label="Tipo de Usuário"
              options={InputSelectOptionsPerfis}
            />
            <InputSelect
              name="ativo"
              label="Ativo?"
              options={InputSelectOptionsSimNao}
            />
          </>
        )}
      </FormRow>
      <FormRow buttons>
        <Button
          type="button"
          variant="danger"
          onClick={() => {
            if (callback) callback()
          }}
        >
          <FaTimesCircle />
          <span>Cancelar</span>
        </Button>

        <Button type="submit">
          <FaCheckCircle />
          <span>Salvar Usuário</span>
        </Button>
      </FormRow>
    </Form>
  )
}

export default UsuarioForm
