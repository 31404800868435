import React from 'react'
import { formatCurrency } from '../../../../utils/currency'
import { dateFormat, formatDateTime } from '../../../../utils/date'
import * as S from './styles'

interface ILabelDetalhesProps {
  label: string
  text?: string
  value?: number
  date?: string
  dateTime?: string
  boolean?: boolean
  obs?: string
}

const LabelDetalhes = ({
  label,
  value,
  text,
  date,
  dateTime,
  boolean,
  obs
}: ILabelDetalhesProps) => {
  return (
    <S.InfoNotasLabelDetalhe>
      <div>{label}</div>
      {!!value && <span>{formatCurrency(value)}</span>}
      {!!date && <span>{formatDateTime(date, dateFormat)}</span>}
      {!!dateTime && <span>{formatDateTime(dateTime)}</span>}
      {!!text && <span>{text}</span>}
      {!!boolean && <span>{boolean ? 'Sim' : 'Não'}</span>}
      {!!obs && <span>Obs: {obs}</span>}
    </S.InfoNotasLabelDetalhe>
  )
}

export default LabelDetalhes
