import * as Yup from 'yup'
import '../../../../utils/yup/yup.locale.pt-br'

const schema = Yup.object().shape({
  razao: Yup.string().min(3).required().nullable(),
  cnpj: Yup.string().required().nullable(),
  email: Yup.string().optional().email()
})

// arquivos: Yup.array()
// .optional()
// .of(
//   Yup.object().shape({
//     arquivo: yupFile(true),
//     categoria: Yup.string().required('Informe uma categoria para o arquivo')
//   })
// )

export const validateForm = async (data: any) => {
  await schema.validate(data, {
    abortEarly: false
  })
}
