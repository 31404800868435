import React, { useCallback, useEffect, useState } from 'react'
import { useRef } from 'react'
import { FaPlus, FaSearch } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { FiDelete, FiEdit } from 'react-icons/fi'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'

import { Button } from '../../../../components/Button'
import Modal, { IModalPropsHandles } from '../../../../components/Modal'
import { Table } from '../../../../components/Table'
import { TableButtonWrapper } from '../../../../components/Table/styles'
import ClienteForm, { ClienteDataForm } from '../../forms/Cliente'
import {
  IApiClienteData,
  IApiClienteSearchFiltro
} from '../../../../services/ApiRequests/ClienteRequests/interfaces'
import { CardBox, CardHeader } from '../../../../styles/Card'
import { formatTelefone } from '../../../../utils/mask'
import { alertConfirma } from '../../../../utils/sweetAlert'
import Api from '../../../../services/Api'
import Loading, { ILoadingHandles } from '../../../../components/Loading'

import { getRole, RoleType } from '../../../../config/Roles'
import { ReduxStore } from '../../../../redux'
import { IUsuarioRedux } from '../../../../redux/modules/usuario/types'
import { ClientesSearch } from './styles'
import InputSelect from '../../../../components/InputSelect'
import Input from '../../../../components/Input'
import Pagination from '../../../../components/Pagination'

const Clientes = () => {
  const modalRef = useRef<IModalPropsHandles>(null)
  const loadRef = useRef<ILoadingHandles>(null)
  const formSearcRef = useRef<FormHandles>(null)

  const { usuario } = useSelector<ReduxStore, IUsuarioRedux>(
    (state) => state.usuario
  )
  const [usuarioRole, setUsuarioRole] = useState<RoleType>()

  useEffect(() => {
    setUsuarioRole(getRole(usuario.tipo))
  }, [usuario.tipo])

  const [cliente, setCliente] = useState<ClienteDataForm>({} as ClienteDataForm)

  const [filtro, setFiltro] = useState<IApiClienteSearchFiltro>()
  const [pagination, setPagination] = useState<{
    limit: number
    count: number
  }>()

  const [dados, setDados] = useState<IApiClienteData[]>()

  const handleLoad = useCallback((search?: IApiClienteSearchFiltro) => {
    loadRef.current?.showLoading()
    setDados([])

    setFiltro(search)

    Api.cliente
      .search(search)
      .then((data) => {
        const { results, ...rest } = data

        setPagination(rest)
        setDados(results)
        loadRef.current?.hideLoading()
      })
      .catch(() => {
        loadRef.current?.hideLoading()
      })
  }, [])

  const handleOnCloseModal = useCallback(() => {
    setCliente({} as ClienteDataForm)
  }, [])

  const handleEdit = useCallback(async ({ id }: ClienteDataForm) => {
    const res = await Api.cliente.getById(id)
    setCliente(res)

    modalRef.current?.openModal()
  }, [])

  const handleDelete = useCallback(
    async ({ id, razao }: ClienteDataForm) => {
      alertConfirma(`Confirma a exclusão do cliente "${razao}"?`, async () => {
        await Api.cliente.delete(id)
        const novos = dados!.filter((cli) => cli.id !== id)
        setDados(novos)
      })
    },
    [dados]
  )

  useEffect(() => {
    formSearcRef.current?.setData({ coluna: 'razao' })
  }, [])

  const handleSubmit: SubmitHandler = async (data, { reset }, event) => {
    if (!formSearcRef.current) return
    event?.preventDefault()
    formSearcRef.current.setErrors({})
    handleLoad({ ...data, page: 1 })
  }

  return (
    <>
      <CardHeader>
        <h1>Clientes</h1>
        <Button onClick={() => modalRef.current?.openModal()}>
          <FaPlus /> <span>Adicionar</span>
        </Button>
      </CardHeader>
      <CardBox>
        <header>
          <h4>Listagem de Clientes</h4>
          <Form ref={formSearcRef} onSubmit={handleSubmit}>
            <ClientesSearch>
              <InputSelect
                isClearable={false}
                name="field"
                options={[
                  { value: 'razao', label: 'Razão' },
                  { value: 'fantasia', label: 'Fantasia' },
                  { value: 'cnpj', label: 'CNPJ' }
                ]}
                width="13rem"
              />
              <Input name="value" placeholder="Digite aqui para pesquisar" />
              <Button type="submit">
                <FaSearch />
              </Button>
            </ClientesSearch>
          </Form>
        </header>
        <div>
          <Table<IApiClienteData & { opcoes: string }>
            headers={{
              fantasia: 'Nome Fantasia',
              razao: 'Razão',
              cnpj: 'CNPJ',
              telefone: 'Telefone',
              opcoes: ''
            }}
            columnSizes={{
              razao: '10rem',
              fantasia: '5rem',
              cnpj: '5rem',
              telefone: '4rem',
              opcoes: '3rem'
            }}
            items={dados}
            customRenderers={{
              telefone: (it) => {
                return formatTelefone(it.telefone)
              },
              opcoes: (it) => (
                <TableButtonWrapper>
                  <Button
                    variant="secondary"
                    onClick={() => handleEdit(it as ClienteDataForm)}
                  >
                    <FiEdit />
                  </Button>
                  {usuarioRole === 'ADMIN' && (
                    <Button
                      variant="danger"
                      onClick={() => handleDelete(it as ClienteDataForm)}
                    >
                      <FiDelete />
                    </Button>
                  )}
                </TableButtonWrapper>
              )
            }}
          />
          <Pagination
            pageLimit={pagination?.limit}
            totalCount={pagination?.count}
            onPageChange={(page) => handleLoad({ ...filtro, page })}
          />
          <Loading ref={loadRef} />
        </div>
      </CardBox>

      <Modal
        ref={modalRef}
        onClose={handleOnCloseModal}
        title={`${cliente.id ? 'Alteração' : 'Cadastro'} de Cliente`}
      >
        <ClienteForm
          callback={() => modalRef.current?.closeModal()}
          cliente={cliente}
        />
      </Modal>
    </>
  )
}

export default Clientes
