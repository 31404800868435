import React, { useEffect, useRef, useState } from 'react'
import { SubmitHandler, FormHandles } from '@unform/core'

import { Button } from '../../../../components/Button'
import Input from '../../../../components/Input'
import { Form, FormRow } from '../../../../styles/form'

import { yupGetErrors } from '../../../../utils/yup/yup.get.errors'
import { validateForm } from './schema'
import { IFormCallback } from '../../../../forms/interfaces'
import InputMask from '../../../../components/InputMask'
import { FaTimesCircle, FaCheckCircle } from 'react-icons/fa'
import Api from '../../../../services/Api'

export interface ClienteDataForm {
  id: number
  licenca: number
  razao: string
  fantasia: string
  cnpj: string
  ie: string
  telefone: string
  email: string
}

interface ClienteFormProps {
  cliente?: ClienteDataForm
}

const ClienteForm: React.FC<IFormCallback & ClienteFormProps> = ({
  callback,
  cliente
}) => {
  const formRef = useRef<FormHandles>(null)
  const [clienteID, setClienteID] = useState<string | null>(null)

  const handleSubmit: SubmitHandler = async (data, { reset }, event) => {
    if (!formRef.current) return

    event?.preventDefault()

    try {
      formRef.current.setErrors({})

      await validateForm(data)
      // console.log(data)

      if (!clienteID) {
        await Api.cliente.save(data)
      } else {
        await Api.cliente.update({ id: clienteID, ...data })
      }

      if (callback) callback()
    } catch (err: any) {
      const errors = yupGetErrors(err)
      formRef.current.setErrors(errors)
    }
  }

  useEffect(() => {
    if (!formRef.current) return

    if (cliente && cliente.id) {
      setClienteID(`${cliente.id}`)
      formRef.current.setData(cliente)
    }
  }, [cliente])

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <FormRow>
        <Input name="fantasia" label="Nome Fantasia" />
        <Input name="razao" label="Razão Social" />
        <Input
          name="cnpj"
          label="CPF/CNPJ"
          // mask="999.999.999-99"
          width="15rem"
          // readOnly={!!clienteID}
        />
        <Input name="ie" label="I.E." width="13rem" />
      </FormRow>

      <FormRow>
        <InputMask
          mask="(99) 99999-9999"
          name="telefone"
          label="Telefone"
          width="14rem"
        />
        <Input name="email" label="E-mail" />
      </FormRow>

      <FormRow buttons>
        <Button
          type="button"
          variant="danger"
          onClick={() => {
            if (callback) callback()
          }}
        >
          <FaTimesCircle />
          <span>Cancelar</span>
        </Button>

        <Button type="submit">
          <FaCheckCircle />
          <span>Salvar Cliente</span>
        </Button>
      </FormRow>
    </Form>
  )
}

export default ClienteForm
