import * as Yup from 'yup'

import '../../../../utils/yup/yup.locale.pt-br'

const schema = Yup.object().shape({
  id: Yup.number().optional(),
  nome: Yup.string().required(),
  versao: Yup.string().required(),
  slug: Yup.string().required(),
  url: Yup.string().required()
})

export const validateForm = async (data: any) => {
  await schema.validate(data, {
    abortEarly: false
  })
}
