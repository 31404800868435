import BaseRequests from '../BaseRequests'
import {
  IApiUsuarioCreate,
  IApiUsuarioData,
  IApiUsuarioList,
  IApiUsuarioSearch,
  IApiUsuarioUpdate
} from './interfaces'

class UsuarioRequests extends BaseRequests {
  constructor() {
    super('usuarios')
  }

  async getById(id: number): Promise<IApiUsuarioData> {
    return this.sendGet<IApiUsuarioData>({ url: `${id}` })
  }

  async get(): Promise<IApiUsuarioList> {
    return this.sendGet<IApiUsuarioList>({})
  }

  async searchCorretor(nome?: string): Promise<IApiUsuarioSearch[]> {
    return this.sendGet<IApiUsuarioSearch[]>({
      url: '/corretor',
      params: { nome }
    })
  }

  async save(data: IApiUsuarioCreate): Promise<void> {
    return this.sendPost({ data })
  }

  async update(data: IApiUsuarioUpdate): Promise<void> {
    return this.sendPut({ data })
  }

  async delete(id: number): Promise<void> {
    return this.sendDelete({ param: `${id}` })
  }
}

export default UsuarioRequests
