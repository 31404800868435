import { TOKEN_KEY } from '../config/global'
import { IApiAuthLoginResult } from '../services/ApiRequests/AuthRequests/interfaces'

export const authGet = (): IApiAuthLoginResult | undefined => {
  const dados = localStorage.getItem(TOKEN_KEY)
  if (dados) return JSON.parse(dados) as IApiAuthLoginResult
}

export const authIsAuthenticated = () => {
  const user = authGet()
  return !!user?.token
}

export const authSet = (user: IApiAuthLoginResult) => {
  localStorage.setItem(TOKEN_KEY, JSON.stringify(user))
}

export const authLogout = () => {
  localStorage.removeItem(TOKEN_KEY)
}

export const authAutoLogin = (
  callbak: (user?: IApiAuthLoginResult) => void
) => {
  const user = authGet()
  if (!!user?.token) callbak(user)
}
