import React, { useCallback, useEffect, useState } from 'react'
import { useRef } from 'react'
import { MdRefresh } from 'react-icons/md'

import { Button } from '../../../../components/Button'
import Modal, { IModalPropsHandles } from '../../../../components/Modal'
import { Table } from '../../../../components/Table'
import { TableButtonWrapper } from '../../../../components/Table/styles'
import Api from '../../../../services/Api'

import { CardBox, CardHeader } from '../../../../styles/Card'

import {
  IApiInfoNotas,
  IApiInfoNotasResumo,
  IApiInfoNotasSearchFiltro
} from '../../../../services/ApiRequests/InfoNotasRequests/interfaces'
import { ILoadingHandles } from '../../../../components/Loading'
import Loading from '../../../../components/Loading'
import { daysBetween, formatDateTime } from '../../../../utils/date'
import {
  FaCalendarTimes,
  FaCircle,
  FaEye,
  FaFrown,
  FaCheckCircle,
  FaMinusCircle
} from 'react-icons/fa'
import InfoNotasDetalhes from '../../components/InfoNotasDetalhes'
import Pagination from '../../../../components/Pagination'
import { getCorCertificadoPorDias } from '../../../../utils/getCorCertificadoPorDias'
import { LegendaBox, LegendaWrapper } from './styles'

const InfoNotas = () => {
  const loadRef = useRef<ILoadingHandles>(null)
  const modalRef = useRef<IModalPropsHandles>(null)
  const [dadosShow, setDadosShow] = useState<IApiInfoNotas>()
  const [dados, setDados] = useState<IApiInfoNotasResumo[]>([])

  const [filtro, setFiltro] = useState<IApiInfoNotasSearchFiltro>()
  const [pagination, setPagination] = useState<{
    limit: number
    count: number
  }>()

  const handleLoad = useCallback((search?: IApiInfoNotasSearchFiltro) => {
    loadRef.current?.showLoading()
    setDados([])
    setFiltro(search)

    Api.infonotas
      .get(search)
      .then((data) => {
        const { results, ...rest } = data
        setPagination(rest)
        setDados(results)
        loadRef.current?.hideLoading()
      })
      .catch(() => {
        loadRef.current?.hideLoading()
      })
  }, [])

  const handleShow = useCallback(async ({ clienteid }: IApiInfoNotasResumo) => {
    const res = await Api.infonotas.getByCliente(clienteid)
    setDadosShow(res)
    modalRef.current?.openModal()
  }, [])

  const handleOnCloseModal = useCallback(() => {
    // setDadoEdit({} as IApiInfoNotasData)
  }, [])

  useEffect(() => {
    handleLoad()
  }, [handleLoad])

  return (
    <>
      <CardHeader>
        <h1>InfoNotas</h1>
        <Button onClick={() => handleLoad()}>
          <MdRefresh /> <span>Atualizar</span>
        </Button>
      </CardHeader>
      <CardBox>
        <header>
          <h4>Listagem de clientes</h4>
        </header>
        <div>
          <Table<IApiInfoNotasResumo>
            headers={{
              fantasia: 'Fantasia',
              razao: 'Razão',
              versao: 'Versão',
              dataenvio: 'Atualização',
              certificadoValidade: 'Certificado',
              backup: 'Backup',
              totalPendenteBackup: 'Pend. Backup',
              totalPendenteAutorizacao: 'Pend. Autorização',
              opcoes: ''
            }}
            columnSizes={{
              versao: '7rem',
              dataenvio: '13rem',
              certificadoValidade: '8rem',
              totalPendenteBackup: '10rem',
              totalPendenteAutorizacao: '10rem',
              opcoes: '5rem'
            }}
            items={dados}
            customRenderers={{
              backup: (it) => {
                return it.backup ? (
                  <FaCheckCircle color="#38A700" size={18} />
                ) : (
                  <FaMinusCircle color="#FF6F59" size={18} />
                )
              },
              dataenvio: (it) => {
                if (it.dataenvio) return formatDateTime(it.dataenvio)
              },
              certificadoValidade: (it) => {
                if (it.certificadoValidade) {
                  const dias = daysBetween(new Date(), it.certificadoValidade)

                  if (dias > 0)
                    return (
                      <FaCalendarTimes
                        size={18}
                        color={getCorCertificadoPorDias(dias)}
                      />
                    )

                  return (
                    <FaCircle
                      size={18}
                      color={getCorCertificadoPorDias(dias)}
                    />
                  )
                  // return  formatDateTime(it.certificadoValidade)
                } else {
                  return <FaFrown size={18} color="#454E9E" />
                }
              },
              opcoes: (it) => (
                <TableButtonWrapper>
                  <Button
                    variant="secondary"
                    onClick={() => handleShow(it as IApiInfoNotasResumo)}
                  >
                    <FaEye />
                  </Button>
                </TableButtonWrapper>
              )
            }}
          />
        </div>
        <Pagination
          pageLimit={pagination?.limit}
          totalCount={pagination?.count}
          onPageChange={(page) => handleLoad({ ...filtro, page })}
        />
        <Loading ref={loadRef} />
        <LegendaWrapper>
          <LegendaBox>
            <div>Dias Vencimento Certificado</div>
            <div>
              <FaCircle size={18} color={getCorCertificadoPorDias(-16)} />
              Mais de 15 dias
            </div>
            <div>
              <FaCircle size={18} color={getCorCertificadoPorDias(-15)} />
              Menor ou igual a 15 dias
            </div>
            <div>
              <FaCircle size={18} color={getCorCertificadoPorDias(-7)} />
              Menor ou igual a 7 dias
            </div>
            <div>
              <FaCalendarTimes size={18} color={getCorCertificadoPorDias(1)} />
              Vencido
            </div>
            <div>
              <FaFrown size={18} color="#454E9E" /> Sem Informação
            </div>
          </LegendaBox>
        </LegendaWrapper>
      </CardBox>
      <Modal
        ref={modalRef}
        title={`Detalhes de ${dadosShow?.cliente.fantasia} (${dadosShow?.cliente.razao})`}
        onClose={handleOnCloseModal}
      >
        <InfoNotasDetalhes dados={dadosShow} />
      </Modal>
    </>
  )
}

export default InfoNotas
