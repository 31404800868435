import React from 'react'
import {
  InputSelectOptionsDocFormaEmissao,
  InputSelectOptionsDocModelos,
  InputSelectOptionsFechamentoTipoIntervalo
} from '../../../../config/InputSelectConfig'
import { IApiInfoNotas } from '../../../../services/ApiRequests/InfoNotasRequests/interfaces'
import { getLabelFromOptions } from '../../../../utils/getLabelFromOptions'
import { formatTelefone } from '../../../../utils/mask'
import LabelDetalhes from './LabelDetalhes'

import * as S from './styles'

interface InfoNotasDetalhesProps {
  dados?: IApiInfoNotas
}

const InfoNotasDetalhes: React.FC<InfoNotasDetalhesProps> = ({ dados }) => {
  if (!dados) return <div />
  return (
    <S.InfoNotasWrapper>
      <S.InfoNotasRow>
        <S.InfoNotasHeader>Informações Gerais</S.InfoNotasHeader>
        <S.InfoNotasRowItens>
          <LabelDetalhes label="Atualização Dados" dateTime={dados.dataenvio} />
          <LabelDetalhes
            label="Validade Certificado"
            dateTime={dados.certificadoValidade}
          />
          <LabelDetalhes
            label="Pendentes de Backup"
            text={`${dados.totalPendenteBackup}`}
          />
          <LabelDetalhes
            label="Pendentes de Autorização"
            text={`${dados.totalPendenteAutorizacao}`}
          />
        </S.InfoNotasRowItens>
      </S.InfoNotasRow>

      <S.InfoNotasRow>
        <S.InfoNotasHeader>Dados do Cliente</S.InfoNotasHeader>
        <S.InfoNotasRowItens>
          <LabelDetalhes label="Razão" text={dados.cliente.razao} />
          <LabelDetalhes label="Fantasia" text={dados.cliente.fantasia} />
        </S.InfoNotasRowItens>
        <S.InfoNotasRowItens>
          <LabelDetalhes label="CNPJ/CPF" text={dados.cliente.cnpj} />
          <LabelDetalhes label="Inscrição Estadual" text={dados.cliente.ie} />
          <LabelDetalhes
            label="Telefone"
            text={formatTelefone(dados.cliente.telefone)}
          />
        </S.InfoNotasRowItens>
      </S.InfoNotasRow>

      <S.InfoNotasRow>
        <S.InfoNotasHeader>Documentos Pendentes</S.InfoNotasHeader>
        <S.InfoNotasCardBox>
          <S.InfoNotasCard>
            <header>NF-e</header>
            <section>{dados.pendentes.nfe}</section>
          </S.InfoNotasCard>
          <S.InfoNotasCard>
            <header>NFC-e</header>
            <section>{dados.pendentes.nfce}</section>
          </S.InfoNotasCard>
        </S.InfoNotasCardBox>
      </S.InfoNotasRow>

      <S.InfoNotasRow>
        <S.InfoNotasHeader>
          Configurações dos Modelos de Emissão
        </S.InfoNotasHeader>
        <S.InfoNotasCardBox>
          {dados.modelos.map((mod, i) => {
            if (mod.modelo === '57') return ''
            return (
              <S.InfoNotasCard key={i}>
                <header>
                  {getLabelFromOptions(
                    InputSelectOptionsDocModelos,
                    mod.modelo
                  )}
                </header>
                <section>
                  <LabelDetalhes
                    label="Forma de Emissão"
                    text={getLabelFromOptions(
                      InputSelectOptionsDocFormaEmissao,
                      mod.formaEmissao
                    )}
                  />
                  <LabelDetalhes
                    label={`${
                      mod.modelo === '98' ? 'Cidade' : 'Versão Manual'
                    }`}
                    text={mod.versaoManual}
                  />
                  <LabelDetalhes
                    label={`${
                      mod.modelo === '98' ? 'Provedor' : 'Versão Esquema'
                    }`}
                    text={mod.versaoEsquema}
                  />
                </section>
              </S.InfoNotasCard>
            )
          })}
        </S.InfoNotasCardBox>
      </S.InfoNotasRow>

      <S.InfoNotasRow>
        <S.InfoNotasHeader>Configuração do Fechamento de Mês</S.InfoNotasHeader>
        <S.InfoNotasRowItens>
          <LabelDetalhes label="Ativo" boolean={dados.fechamento?.ativo} />
          <LabelDetalhes
            label="Cadastro"
            dateTime={dados.fechamento?.cadastro}
          />
          <LabelDetalhes
            label="Ult. Execução"
            dateTime={dados.fechamento?.ultimaexc}
          />
          <LabelDetalhes
            label="Tipo Intervalo"
            text={getLabelFromOptions(
              InputSelectOptionsFechamentoTipoIntervalo,
              dados.fechamento?.tipoIntervalo
            )}
          />
        </S.InfoNotasRowItens>
        <S.InfoNotasRowItens>
          <LabelDetalhes
            label="Intervalo (Tempo)"
            text={`${dados.fechamento?.intervalo || ''}`}
          />
          <LabelDetalhes
            label="Horário Iniciar"
            text={dados.fechamento?.horarioInicio}
          />
          <LabelDetalhes
            label="Horário Final"
            text={dados.fechamento?.horarioFinal}
          />
        </S.InfoNotasRowItens>
      </S.InfoNotasRow>
    </S.InfoNotasWrapper>
  )
}

export default InfoNotasDetalhes
