import React, { useCallback, useEffect, useState } from 'react'
import { useRef } from 'react'
import { FaPlus, FaSearch } from 'react-icons/fa'
import { FiDelete, FiEdit } from 'react-icons/fi'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'

import { Button } from '../../../../components/Button'
import Modal, { IModalPropsHandles } from '../../../../components/Modal'
import { Table } from '../../../../components/Table'
import { TableButtonWrapper } from '../../../../components/Table/styles'

import { CardBox, CardHeader } from '../../../../styles/Card'
import { alertConfirma } from '../../../../utils/sweetAlert'
import Api from '../../../../services/Api'
import Loading, { ILoadingHandles } from '../../../../components/Loading'
import {
  IApiWebsetupModuloData,
  IApiWebsetupModuloSearch,
  IApiWebsetupModuloUpdate
} from '../../../../services/ApiRequests/WebSetupModulosRequests/interfaces'
import { ModulosSearch } from './styles'
import InputSelect from '../../../../components/InputSelect'
import Input from '../../../../components/Input'
import WebsetupModuloForm from '../../form/Modulo'

const Modulos = () => {
  const modalRef = useRef<IModalPropsHandles>(null)
  const loadRef = useRef<ILoadingHandles>(null)
  const formSearcRef = useRef<FormHandles>(null)

  const [modulo, setModulo] = useState<IApiWebsetupModuloUpdate>(
    {} as IApiWebsetupModuloUpdate
  )
  const [dados, setDados] = useState<IApiWebsetupModuloData[]>([])

  const handleLoad = useCallback((filtro: IApiWebsetupModuloSearch) => {
    loadRef.current?.showLoading()
    setDados([])

    Api.websetupModulos
      .get(filtro)
      .then((data) => {
        setDados(data)
        loadRef.current?.hideLoading()
      })
      .catch(() => {
        loadRef.current?.hideLoading()
      })
  }, [])

  const handleOnCloseModal = useCallback(() => {
    setModulo({} as IApiWebsetupModuloUpdate)
    formSearcRef.current?.submitForm()
  }, [])

  const handleEdit = useCallback(async ({ id }: IApiWebsetupModuloData) => {
    const res = await Api.websetupModulos.getById(id)
    setModulo(res)

    modalRef.current?.openModal()
  }, [])

  const handleDelete = useCallback(
    async ({ id, nome }: IApiWebsetupModuloData) => {
      alertConfirma(`Confirma a exclusão do modulo "${nome}"?`, async () => {
        await Api.websetupModulos.delete(id)
        const novos = dados.filter((ver) => ver.id !== id)
        setDados(novos)
      })
    },
    [dados]
  )

  const handleSubmit: SubmitHandler = async (data, { reset }, event) => {
    if (!formSearcRef.current) return
    event?.preventDefault()
    formSearcRef.current.setErrors({})
    handleLoad(data)
  }

  useEffect(() => {
    formSearcRef.current?.setData({ coluna: 'nome' })
  }, [])

  return (
    <>
      <CardHeader>
        <h1>Modulos</h1>
        <Button onClick={() => modalRef.current?.openModal()}>
          <FaPlus /> <span>Adicionar</span>
        </Button>
      </CardHeader>
      <CardBox>
        <header>
          <h4>Listagem de Modulos</h4>
          <Form ref={formSearcRef} onSubmit={handleSubmit}>
            <ModulosSearch>
              <InputSelect
                isClearable={false}
                name="field"
                options={[
                  { value: 'nome', label: 'Nome' },
                  { value: 'slug', label: 'Slug' },
                  { value: 'versao', label: 'Versão' }
                ]}
                width="13rem"
              />
              <Input name="value" placeholder="Digite aqui para pesquisar" />
              <Button type="submit">
                <FaSearch />
              </Button>
            </ModulosSearch>
          </Form>
        </header>
        <div>
          <Table<IApiWebsetupModuloData>
            headers={{
              nome: 'Nome',
              versao: 'Versão',
              slug: 'Slug',
              opcoes: ''
            }}
            columnSizes={{
              opcoes: '8rem'
            }}
            items={dados}
            customRenderers={{
              opcoes: (it) => (
                <TableButtonWrapper>
                  <Button
                    variant="secondary"
                    onClick={() => handleEdit(it as IApiWebsetupModuloData)}
                  >
                    <FiEdit />
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => handleDelete(it as IApiWebsetupModuloData)}
                  >
                    <FiDelete />
                  </Button>
                </TableButtonWrapper>
              )
            }}
          />
          <Loading ref={loadRef} />
        </div>
      </CardBox>

      <Modal
        ref={modalRef}
        onClose={handleOnCloseModal}
        title={`${modulo.id ? 'Alteração' : 'Cadastro'} de Módulo`}
      >
        <WebsetupModuloForm
          callback={() => modalRef.current?.closeModal()}
          modulo={modulo}
        />
      </Modal>
    </>
  )
}

export default Modulos
