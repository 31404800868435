import styled from 'styled-components'

export const LegendaWrapper = styled.div`
  display: flex
`
export const LegendaBox = styled.div`
  display: flex
  flex-direction: column;

  padding: 0.5rem;

  div:first-child{
    font-weight: bold;
  }

  div {
    display: flex;
    align-items: center;
    svg {
      margin-right: 0.5rem
    }

    margin-bottom: 0.5rem
  }
`