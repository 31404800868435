import axios from 'axios'
import { addToast } from '../../components/Toast'
import { authGet, authLogout } from '../../hooks/auth'
// import { alertError } from '../../utils/sweetAlert'
import {
  IHttpRequest,
  IHttpRequestDelete,
  IHttpRequestGet,
  IHttpRequestPost,
  IHttpRequestPut
} from './interfaces'

export const request = axios.create({
  baseURL: `${process.env.REACT_APP_HOST}/api`
})

request.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    const msg = error?.response?.data?.error || error?.response?.data?.message

    if (msg) {
      addToast({ title: 'Houve um problema!', message: msg, type: 'error' })
    }

    if (error.response?.status && error.response.status === 401) {
      if (error.response.config.url !== '/auth') {
        authLogout()
        window.location.href = '/'
      }
    }

    return Promise.reject(error)
  }
)

request.interceptors.request.use(async (config) => {
  if (!config.headers.Authorization) {
    const user = authGet()
    config.headers.Authorization = `Bearer ${user?.token || ''}`
  }
  return config
})

class HttpRequest implements IHttpRequest {
  post = async <T>({ url, data, multipart }: IHttpRequestPost): Promise<T> => {
    const headers = {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
    return request
      .post<T>(`${url}`, data, multipart ? headers : {})
      .then((res) => res.data)
  }

  put = async <T>({ url, data, multipart }: IHttpRequestPut): Promise<T> => {
    const headers = {
      headers: { 'Content-Type': 'multipart/form-data' }
    }
    return request
      .put<T>(`${url}`, data, multipart ? headers : {})
      .then((res) => res.data)
  }

  get = async <T>({
    url,
    params,
    responseType
  }: IHttpRequestGet): Promise<T> => {
    return request
      .get(`${url}`, {
        params,
        ...(responseType && { responseType })
      })
      .then((res) => res.data)
  }

  delete = async <T>({ url, param }: IHttpRequestDelete): Promise<T> => {
    return request.delete<T>(`${url}/${param}`).then((res) => res.data)
  }
}

export default HttpRequest
