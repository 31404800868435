import React, { useCallback, useEffect, useState } from 'react'
import { useRef } from 'react'
import { FaPlus } from 'react-icons/fa'

import { FiDelete, FiEdit } from 'react-icons/fi'
import { Button } from '../../../../components/Button'
import Modal, { IModalPropsHandles } from '../../../../components/Modal'
import { Table } from '../../../../components/Table'
import { TableButtonWrapper } from '../../../../components/Table/styles'
import Api from '../../../../services/Api'

import { CardBox, CardHeader } from '../../../../styles/Card'
import { alertConfirma } from '../../../../utils/sweetAlert'
import { IApiSistemaData } from '../../../../services/ApiRequests/SistemaRequests/interfaces'
import { useSelector } from 'react-redux'
import { ReduxStore } from '../../../../redux'
import { IUsuarioRedux } from '../../../../redux/modules/usuario/types'
import { getRole, RoleType } from '../../../../config/Roles'
import SistemaForm from '../../form/Sistema'
import Loading, { ILoadingHandles } from '../../../../components/Loading'

interface SistemaItem extends IApiSistemaData {
  opcoes: string
}

const Sistemas = () => {
  const modalRef = useRef<IModalPropsHandles>(null)
  const loadRef = useRef<ILoadingHandles>(null)
  const [dadoEdit, setDadoEdit] = useState<IApiSistemaData>()
  const [dados, setDados] = useState<IApiSistemaData[]>([])

  const usuarioLogado = useSelector<ReduxStore, IUsuarioRedux>(
    (state) => state.usuario
  )
  const [usuarioRole, setUsuarioRole] = useState<RoleType>()

  useEffect(() => {
    setUsuarioRole(getRole(usuarioLogado.usuario.tipo))
  }, [usuarioLogado.usuario.tipo])

  const handleLoad = useCallback(() => {
    loadRef.current?.showLoading()
    Api.sistema.get().then((data) => {
      setDados(data.results)
      loadRef.current?.hideLoading()
    })
  }, [])

  const handleEdit = useCallback(async ({ id }: IApiSistemaData) => {
    const res = await Api.sistema.getById(id)
    setDadoEdit(res)
    modalRef.current?.openModal()
  }, [])

  const handleDelete = useCallback(
    async ({ id, nome }: IApiSistemaData) => {
      alertConfirma(`Confirma a exclusão do sistema "${nome}"?`, async () => {
        await Api.sistema.delete(id)
        handleLoad()
      })
    },
    [handleLoad]
  )

  const handleOnCloseModal = useCallback(() => {
    setDadoEdit({} as IApiSistemaData)
    handleLoad()
  }, [handleLoad])

  useEffect(() => {
    handleLoad()
  }, [handleLoad])

  return (
    <>
      <CardHeader>
        <h1>Sistemas</h1>
        {usuarioRole === 'ADMIN' && (
          <Button onClick={() => modalRef.current?.openModal()}>
            <FaPlus /> <span>Adicionar</span>
          </Button>
        )}
      </CardHeader>
      <CardBox>
        <header>
          <h4>Listagem de Sistemas</h4>
        </header>
        <div>
          <Table<SistemaItem>
            headers={{
              id: 'Id',
              nome: 'Nome',
              slug: 'Slug',
              opcoes: ''
            }}
            columnSizes={{
              id: '5rem',
              opcoes: '10rem'
            }}
            items={dados}
            customRenderers={{
              opcoes: (it) => (
                <TableButtonWrapper>
                  <Button
                    variant="secondary"
                    onClick={() => handleEdit(it as IApiSistemaData)}
                  >
                    <FiEdit />
                  </Button>
                  {usuarioRole === 'ADMIN' && (
                    <Button
                      variant="danger"
                      onClick={() => handleDelete(it as IApiSistemaData)}
                    >
                      <FiDelete />
                    </Button>
                  )}
                </TableButtonWrapper>
              )
            }}
          />
          <Loading ref={loadRef} />
        </div>
      </CardBox>

      <Modal
        ref={modalRef}
        title={`${
          dadoEdit?.id ? `Alteração do ${dadoEdit.nome}` : 'Cadastro de Sistema'
        }`}
        onClose={handleOnCloseModal}
      >
        <SistemaForm
          callback={() => modalRef.current?.closeModal()}
          sistema={dadoEdit}
        />
      </Modal>
    </>
  )
}

export default Sistemas
