import React, { useRef, useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { SubmitHandler, FormHandles } from '@unform/core'
import * as Yup from 'yup'

import Input from '../../components/Input'
import { Button } from '../../components/Button'

import { yupGetErrors } from '../../utils/yup/yup.get.errors'
import { Form, FormRow } from '../../styles/form'

import { Box, Container } from './styles'
import { addToast } from '../../components/Toast'
import { HEADER_SIDEBAR } from '../../config/global'
import Api from '../../services/Api'
import { useDispatch } from 'react-redux'
import { setUsuario } from '../../redux/modules/usuario/actions'
import { authGet, authSet } from '../../hooks/auth'
import Loading, { ILoadingHandles } from '../../components/Loading'

const Login: React.FC = () => {
  const formRef = useRef<FormHandles>(null)
  const loadRef = useRef<ILoadingHandles>(null)
  const [showForm, setShowForm] = useState(true)

  const history = useHistory()
  const dispatch = useDispatch()

  const handleSubmit: SubmitHandler = async (data, { reset }, event) => {
    if (!formRef.current) return

    event?.preventDefault()

    try {
      const schema = Yup.object().shape({
        email: Yup.string().email().required(),
        senha: Yup.string().min(4).required()
      })

      await schema.validate(data, { abortEarly: false })

      loadRef.current?.showLoading()
      const authLogin = await Api.auth.login(data)
      dispatch(setUsuario(authLogin))

      if (authLogin.token) {
        const usuario = await Api.usuario.getById(authLogin.usuario.id)

        if (usuario) {
          const login = { usuario, token: authLogin.token }

          authSet(login)
          dispatch(setUsuario(login))

          history.push('/dashboard')
        }
      }
    } catch (err) {
      loadRef.current?.hideLoading()
      const errors = yupGetErrors(err)
      const keys = Object.keys(errors)
      if (keys.length > 0)
        addToast({
          title: 'Campos obrigatórios',
          message: errors[keys[0]],
          type: 'info'
        })
    }
  }

  const user = authGet()

  if (!!user?.token) return <Redirect to={{ pathname: '/dashboard' }} />

  return (
    <Container>
      <Box>
        <header>
          <h2>{HEADER_SIDEBAR}</h2>
        </header>
        <main>
          <h4>Acessar Sistema</h4>
          <Loading
            ref={loadRef}
            onLoadingChange={(value) => {
              setShowForm(!value)
            }}
          />
          {showForm && (
            <Form ref={formRef} onSubmit={handleSubmit}>
              <FormRow>
                <Input name="email" label="Endereço de e-mail" />
              </FormRow>
              <FormRow>
                <Input name="senha" label="Informe sua Senha" type="password" />
              </FormRow>
              <FormRow>
                <Button type="submit">Entrar</Button>
              </FormRow>
            </Form>
          )}
        </main>
      </Box>
    </Container>
  )
}

export default Login
