import React, { useEffect, useRef, InputHTMLAttributes } from 'react'
import { IconBaseProps } from 'react-icons'
import { useField } from '@unform/core'
import { FormGroup } from '../../styles/form'

// import * as S from './styles'

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string
  label?: string
  icon?: React.ComponentType<IconBaseProps>
}

const Input: React.FC<InputProps> = ({ icon: Icon, name, label, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const {
    fieldName,
    defaultValue,
    registerField,
    error,
    clearError
  } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return ref.current?.value
      },
      setValue: (ref, value) => {
        if (value) ref.current.value = value
      },
      clearValue: (ref) => {
        ref.current.value = ''
      }
    })
  }, [fieldName, registerField])

  return (
    <FormGroup width={rest.width}>
      {label && <label htmlFor={fieldName}>{label}</label>}
      <div>
        {Icon && <Icon size={23} />}
        <input
          id={fieldName}
          ref={inputRef}
          defaultValue={defaultValue}
          className={error ? 'has-error' : ''}
          onFocus={clearError}
          {...rest}
        />
      </div>
      {error && <span className="error">{error}</span>}
    </FormGroup>
  )
}

export default Input
