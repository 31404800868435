import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { reducers } from './modules/rootReducer'
import { IUsuarioRedux } from './modules/usuario/types'

export type ReduxStore = {
  usuario: IUsuarioRedux
}

export const store = createStore(
  reducers,
  composeWithDevTools(applyMiddleware(thunk))
)
