import styled, { css } from 'styled-components'

export const InfoNotasWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const InfoNotasRow = styled.div`
  display:flex;
  flex: 1;
  margin-bottom: 0.8rem;

  flex-direction: column;
`



export const InfoNotasHeader = styled.h3`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.primary};
`

export const InfoNotasRowItens = styled.div`
  display:flex;
  flex: 1;
`


export const InfoNotasLabelDetalhe = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;

  div {
    color: ${({ theme }) => theme.colors.font};
    font-size: 1.1rem;
    font-weight: bold;
  }
  span {
    margin-top: 0.3rem;
  }
`


export const InfoNotasCardBox = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding-top: 0.6rem;

  ${({ theme }) => css`
    ${theme.media.phone} {
      grid-template-columns: repeat(1, 1fr);
    }
  `}  

`
export const InfoNotasCard = styled.main`
  padding: 1rem;
  margin: 0 0.5rem;

  box-shadow: 0 0 40px rgb(0 0 0 / 5%);
  background-color: #f0effc;
  border-radius: 3px;

  margin-bottom: 1rem;

  header {
    font-size: 1.2rem;
    letter-spacing: 0.05rem;
    color: ${({ theme }) => theme.colors.fontdark};
    font-weight: bold;
  }
  section {
    padding-top: 0.5rem;
    display: flex;
    flex: 1;
  }
`