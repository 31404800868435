import AuthRequests from './AuthRequests'
import ClienteRequests from './ClienteRequests'
import InfoNotasRequests from './InfoNotasRequests'
import SistemaRequests from './SistemaRequests'
import SistemaVersoesRequests from './SistemaVersoesRequests'
import UsuarioRequests from './UsuarioRequests'
import WebsetupModulosRequests from './WebSetupModulosRequests'
import WebsetupRequests from './WebSetupRequests'

class ApiRequests {
  public cliente: ClienteRequests
  public auth: AuthRequests
  public usuario: UsuarioRequests
  public sistema: SistemaRequests
  public sistemaVersoes: SistemaVersoesRequests
  public websetup: WebsetupRequests
  public websetupModulos: WebsetupModulosRequests
  public infonotas: InfoNotasRequests

  constructor() {
    this.cliente = new ClienteRequests()
    this.auth = new AuthRequests()
    this.usuario = new UsuarioRequests()
    this.sistema = new SistemaRequests()
    this.sistemaVersoes = new SistemaVersoesRequests()
    this.websetup = new WebsetupRequests()
    this.websetupModulos = new WebsetupModulosRequests()
    this.infonotas = new InfoNotasRequests()
  }
}

export default ApiRequests
