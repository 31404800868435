import { alertClose, alertLoading } from '../../../utils/sweetAlert'
import BaseRequests from '../BaseRequests'
import { IApiInfoNotas, IApiInfoNotasFechamento, IApiInfoNotasResumoGeral, IApiInfoNotasSearch, IApiInfoNotasSearchFiltro } from './interfaces'


class InfoNotasRequests extends BaseRequests {
  constructor() {
    super('infonotas')
  }

  async get(filtro?: IApiInfoNotasSearchFiltro): Promise<IApiInfoNotasSearch> {
    return this.sendGet<IApiInfoNotasSearch>({ params: { ...filtro } })
  }

  async resumo(): Promise<IApiInfoNotasResumoGeral> {
    return this.sendGet<IApiInfoNotasResumoGeral>({ url: 'resumo' })
  }

  async getByCliente(id: number): Promise<IApiInfoNotas> {
    alertLoading('Carregando...')
    return this.sendGet<IApiInfoNotas>({ url: `${id}` }).then((data) => {
      alertClose()
      return data
    })
      .catch((err) => {
        alertClose()
        throw err
      })
  }

  async getFechamentoByCliente(id: number): Promise<IApiInfoNotasFechamento[]> {
    return this.sendGet<IApiInfoNotasFechamento[]>({ url: `fechamento/${id}` })
  }

}

export default InfoNotasRequests
