export const InputSelectOptionsSimNao = [
  { value: 'S', label: 'Sim' },
  { value: 'N', label: 'Não' }
]

export const InputSelectOptionsPerfis = [
  { value: 'ADMIN', label: 'Administrador' },
  { value: 'DEV', label: 'Desenvolvedor' },
  { value: 'SUPORTE', label: 'Suporte' }
]

export const InputSelectOptionsDocModelos = [
  { value: '55', label: 'NF-e' },
  { value: '57', label: 'CT-e' },
  { value: '58', label: 'MDF-e' },
  { value: '65', label: 'NFC-e' },
  { value: '98', label: 'NFS-e' },
]

export const InputSelectOptionsDocFormaEmissao = [
  { value: '1', label: 'Normal' },
  { value: '2', label: 'Contingência' },
  { value: '4', label: 'EPEC' },
  { value: '7', label: 'SVC-RS' },
  { value: '9', label: 'Offline' },
]

export const InputSelectOptionsFechamentoTipoIntervalo = [
  { value: '1', label: 'Diário' },
  { value: '2', label: 'Mensal' },
  { value: '3', label: 'Minutos' },
  { value: '4', label: 'Quinzenal' },
  { value: '5', label: 'Semanal' },
]
