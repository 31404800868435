import React, { useCallback, useEffect, useState } from 'react'
import { useRef } from 'react'
import { FaPlus, FaSearch } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { FiDelete, FiEdit } from 'react-icons/fi'
import { FormHandles, SubmitHandler } from '@unform/core'
import { Form } from '@unform/web'

import { Button } from '../../../../components/Button'
import Modal, { IModalPropsHandles } from '../../../../components/Modal'
import { Table } from '../../../../components/Table'
import { TableButtonWrapper } from '../../../../components/Table/styles'

import { CardBox, CardHeader } from '../../../../styles/Card'
import { alertConfirma, alertError } from '../../../../utils/sweetAlert'
import Api from '../../../../services/Api'
import Loading, { ILoadingHandles } from '../../../../components/Loading'

import { getRole, RoleType } from '../../../../config/Roles'
import { ReduxStore } from '../../../../redux'
import { IUsuarioRedux } from '../../../../redux/modules/usuario/types'
import { VersoesSearch } from './styles'
import SistemaVersoesForm from '../../form/Versoes'
import InputSelectAsync from '../../../../components/InputSelectAsync'
import { IApiSistemaVersaoData } from '../../../../services/ApiRequests/SistemaVersoesRequests/interfaces'

const Versoes = () => {
  const modalRef = useRef<IModalPropsHandles>(null)
  const loadRef = useRef<ILoadingHandles>(null)
  const formSearcRef = useRef<FormHandles>(null)

  const { usuario } = useSelector<ReduxStore, IUsuarioRedux>(
    (state) => state.usuario
  )
  const [usuarioRole, setUsuarioRole] = useState<RoleType>()

  useEffect(() => {
    setUsuarioRole(getRole(usuario.tipo))
  }, [usuario.tipo])

  const [sistema, setSistema] = useState<number>()
  const [versao, setVersao] = useState<IApiSistemaVersaoData>(
    {} as IApiSistemaVersaoData
  )
  const [dados, setDados] = useState<IApiSistemaVersaoData[]>([])

  const handleLoad = useCallback((dados: any) => {
    if (!dados.sistema) {
      alertError('Informe o Sistema para pesquisar')
      return
    }
    loadRef.current?.showLoading()
    setDados([])
    setSistema(dados.sistema)

    Api.sistemaVersoes
      .get(dados.sistema)
      .then((data) => {
        setDados(data)
        loadRef.current?.hideLoading()
      })
      .catch(() => {
        loadRef.current?.hideLoading()
      })
  }, [])

  const handleOnCloseModal = useCallback(() => {
    setVersao({} as IApiSistemaVersaoData)
    if (sistema) {
      console.log(sistema)
      handleLoad({ sistema })
    }
  }, [handleLoad, sistema])

  const handleEdit = useCallback(async ({ id }: IApiSistemaVersaoData) => {
    const res = await Api.sistemaVersoes.getById(id)
    setVersao(res)

    modalRef.current?.openModal()
  }, [])

  const handleDelete = useCallback(
    async ({ id, versao }: IApiSistemaVersaoData) => {
      alertConfirma(`Confirma a exclusão da versão "${versao}"?`, async () => {
        await Api.sistemaVersoes.delete(id)
        const novos = dados.filter((ver) => ver.id !== id)
        setDados(novos)
      })
    },
    [dados]
  )

  const handleSubmit: SubmitHandler = async (data, { reset }, event) => {
    if (!formSearcRef.current) return
    event?.preventDefault()
    formSearcRef.current.setErrors({})
    handleLoad(data)
  }

  const handleSearchSistema = useCallback(async (inputValue?: string) => {
    return Api.sistema
      .get({ field: 'nome', value: inputValue })
      .then((data) => {
        const arrayData = data.results.map((sis) => ({
          label: sis.nome,
          value: sis.id
        }))
        return arrayData
      })
  }, [])

  return (
    <>
      <CardHeader>
        <h1>Versoes</h1>
        {usuarioRole !== 'SUPORTE' && (
          <Button onClick={() => modalRef.current?.openModal()}>
            <FaPlus /> <span>Adicionar</span>
          </Button>
        )}
      </CardHeader>
      <CardBox>
        <header>
          <h4>Listagem de Versoes</h4>
          <Form ref={formSearcRef} onSubmit={handleSubmit}>
            <VersoesSearch>
              <InputSelectAsync
                isClearable={false}
                name="sistema"
                placeholder="Digite um sistema para pesquisar"
                asyncLoadOptions={handleSearchSistema}
                width="30rem"
              />
              <Button type="submit">
                <FaSearch />
              </Button>
            </VersoesSearch>
          </Form>
        </header>
        <div>
          <Table<IApiSistemaVersaoData>
            headers={{
              versao: 'Versao',
              teste: 'Teste',
              liberado: 'Liberado',
              ativo: 'Ativo',
              autoupdate: 'AutoUpdate',
              autoupdatehash: 'AutoUpdate Hash',
              opcoes: ''
            }}
            columnSizes={{
              versao: '15rem',
              teste: '8rem',
              liberado: '8rem',
              ativo: '8rem',
              autoupdate: '8rem',
              autoupdatehash: '25rem',
              opcoes: '8rem'
            }}
            items={dados}
            customRenderers={{
              teste: (it) => {
                return it.teste ? 'Sim' : 'Não'
              },
              ativo: (it) => {
                return it.ativo ? 'Sim' : 'Não'
              },
              liberado: (it) => {
                return it.liberado ? 'Sim' : 'Não'
              },
              autoupdate: (it) => {
                return it.autoupdate ? 'Sim' : 'Não'
              },
              opcoes: (it) => (
                <TableButtonWrapper>
                  {usuarioRole !== 'SUPORTE' && (
                    <>
                      <Button
                        variant="secondary"
                        onClick={() => handleEdit(it as IApiSistemaVersaoData)}
                      >
                        <FiEdit />
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() =>
                          handleDelete(it as IApiSistemaVersaoData)
                        }
                      >
                        <FiDelete />
                      </Button>
                    </>
                  )}
                </TableButtonWrapper>
              )
            }}
          />
          <Loading ref={loadRef} />
        </div>
      </CardBox>

      <Modal
        ref={modalRef}
        onClose={handleOnCloseModal}
        title={`${versao.id ? 'Alteração' : 'Cadastro'} de Versão`}
      >
        <SistemaVersoesForm
          callback={() => modalRef.current?.closeModal()}
          versao={versao}
        />
      </Modal>
    </>
  )
}

export default Versoes
