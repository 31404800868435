import {
  alertClose,
  alertLoading,
  alertSucesso
} from '../../../utils/sweetAlert'
import BaseRequests from '../BaseRequests'
import {
  IApiWebsetupModuloCreate,
  IApiWebsetupModuloData,
  IApiWebsetupModuloSearch,
  IApiWebsetupModuloUpdate,
} from './interfaces'

class WebsetupModulosRequests extends BaseRequests {
  constructor() {
    super('websetup/modulos')
  }


  async getById(id: number): Promise<IApiWebsetupModuloUpdate> {
    return this.sendGet<IApiWebsetupModuloUpdate>({ url: `${id}` })
  }

  async get(
    filtro: IApiWebsetupModuloSearch
  ): Promise<IApiWebsetupModuloData[]> {
    return this.sendGet<IApiWebsetupModuloData[]>({
      params: { ...filtro }
    })
  }

  async save(data: IApiWebsetupModuloCreate): Promise<void> {
    alertLoading('Cadastrando Módulo...')
    return this.sendPost({ data })
      .then(() => {
        alertSucesso()
      })
      .catch((err) => {
        alertClose()
        throw err
      })
  }

  async update(data: IApiWebsetupModuloUpdate): Promise<void> {
    alertLoading('Atualizando Módulo...')
    return this.sendPut({ data })
      .then(() => {
        alertSucesso()
      })
      .catch((err) => {
        alertClose()
        throw err
      })
  }

  async delete(id: number): Promise<void> {
    return this.sendDelete({ param: `${id}` })
  }
}

export default WebsetupModulosRequests
