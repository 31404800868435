import * as S from './styles'

import { useSelector } from 'react-redux'
import { ReduxStore } from '../../../../redux'
import { IUsuarioRedux } from '../../../../redux/modules/usuario/types'
import { getLabelFromOptions } from '../../../../utils/getLabelFromOptions'
import { InputSelectOptionsPerfis } from '../../../../config/InputSelectConfig'

interface IPropsNavbar {
  openSidebar: () => void
}

const Navbar = ({ openSidebar }: IPropsNavbar) => {
  const user = useSelector<ReduxStore, IUsuarioRedux>((state) => state.usuario)

  return (
    <S.Navbar id="navbar">
      <S.NavbarToggleBar onClick={() => openSidebar()}>
        <i className="fa fa-bars"></i>
      </S.NavbarToggleBar>
      <S.NavbarLeft></S.NavbarLeft>

      <S.NavbarRight>
        {/* <S.Icon>
          <FaBell onClick={() => dispatch(showNotificacao())} />
        </S.Icon> */}
        <S.NavbarRightUser href="#">
          {/* <img src="/img/avatar.png" alt="avatar" width="30" /> */}
          {user?.usuario?.nome && (
            <>
              <div>{user.usuario.nome.split(' ')[0]}</div>
              <span>
                {getLabelFromOptions(
                  InputSelectOptionsPerfis,
                  user.usuario.tipo
                )}
              </span>
            </>
          )}
        </S.NavbarRightUser>
      </S.NavbarRight>
    </S.Navbar>
  )
}

export default Navbar
