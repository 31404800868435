import React, { useEffect, useRef } from 'react'
import { SubmitHandler, FormHandles } from '@unform/core'

import { Button } from '../../../../components/Button'
import Input from '../../../../components/Input'
import { Form, FormRow } from '../../../../styles/form'

import { yupGetErrors } from '../../../../utils/yup/yup.get.errors'
import { validateForm } from './schema'
import { IFormCallback } from '../../../../forms/interfaces'
import { FaTimesCircle, FaCheckCircle } from 'react-icons/fa'

import Api from '../../../../services/Api'
import { IApiWebsetupModuloUpdate } from '../../../../services/ApiRequests/WebSetupModulosRequests/interfaces'

interface WebsetupModuloFormProps {
  modulo?: IApiWebsetupModuloUpdate
}

const WebsetupModuloForm: React.FC<IFormCallback & WebsetupModuloFormProps> = ({
  callback,
  modulo
}) => {
  const formRef = useRef<FormHandles>(null)

  useEffect(() => {
    if (!formRef.current) return

    if (modulo && modulo.id) {
      formRef.current.setData(modulo)
    }
  }, [modulo])

  const handleSubmit: SubmitHandler = async (data, { reset }, event) => {
    if (!formRef.current) return
    event?.preventDefault()

    try {
      formRef.current.setErrors({})

      await validateForm({
        ...data,
        id: modulo?.id
      })

      if (modulo?.id) {
        await Api.websetupModulos.update({
          ...data,
          id: modulo?.id
        })
      } else {
        await Api.websetupModulos.save(data)
      }

      if (callback) callback()
    } catch (err) {
      const errors = yupGetErrors(err)
      formRef.current.setErrors(errors)
    }
  }

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <FormRow>
        <Input name="nome" label="Nome" />
        <Input name="versao" label="Versão" />
        <Input name="slug" label="Slug" />
      </FormRow>
      <FormRow>
        <Input name="url" label="URL" />
      </FormRow>
      <FormRow>
        <Input name="urlx64" label="URL x64" />
      </FormRow>
      <FormRow>
        <Input name="urlmirror" label="URL Mirror" />
      </FormRow>
      <FormRow>
        <Input name="urlmirrorx64" label="URL Mirror x64" />
      </FormRow>
      <FormRow buttons>
        <Button
          type="button"
          variant="danger"
          onClick={() => {
            if (callback) callback()
          }}
        >
          <FaTimesCircle />
          <span>Cancelar</span>
        </Button>

        <Button type="submit">
          <FaCheckCircle />
          <span>Salvar</span>
        </Button>
      </FormRow>
    </Form>
  )
}

export default WebsetupModuloForm
