import React, { useCallback, useEffect, useState } from 'react'
import { useRef } from 'react'
import { FaPlus } from 'react-icons/fa'

import { FiDelete, FiEdit } from 'react-icons/fi'
import { Button } from '../../../../components/Button'
import Modal, { IModalPropsHandles } from '../../../../components/Modal'
import { Table } from '../../../../components/Table'
import { TableButtonWrapper } from '../../../../components/Table/styles'
import Api from '../../../../services/Api'

import { CardBox, CardHeader } from '../../../../styles/Card'
import { alertConfirma } from '../../../../utils/sweetAlert'
import { IApiWebsetupData } from '../../../../services/ApiRequests/WebSetupRequests/interfaces'
import WebsetupForm from '../../form/Websetup'

interface WebsetupItem extends IApiWebsetupData {
  opcoes: string
}

const Websetup = () => {
  const modalRef = useRef<IModalPropsHandles>(null)
  const [dadoEdit, setDadoEdit] = useState<IApiWebsetupData>()
  const [dados, setDados] = useState<IApiWebsetupData[]>([])

  const handleLoad = useCallback(() => {
    Api.websetup.get().then((data) => {
      setDados(data)
    })
  }, [])

  const handleEdit = useCallback(async ({ id }: IApiWebsetupData) => {
    const res = await Api.websetup.getById(id)
    setDadoEdit(res)
    modalRef.current?.openModal()
  }, [])

  const handleDelete = useCallback(
    async ({ id, sistema }: IApiWebsetupData) => {
      alertConfirma(
        `Confirma a exclusão do Websetup do sistema "${sistema.nome}"?`,
        async () => {
          await Api.websetup.delete(id)
          handleLoad()
        }
      )
    },
    [handleLoad]
  )

  const handleOnCloseModal = useCallback(() => {
    setDadoEdit({} as IApiWebsetupData)
    handleLoad()
  }, [handleLoad])

  useEffect(() => {
    handleLoad()
  }, [handleLoad])

  return (
    <>
      <CardHeader>
        <h1>Websetup</h1>
        <Button onClick={() => modalRef.current?.openModal()}>
          <FaPlus /> <span>Adicionar</span>
        </Button>
      </CardHeader>
      <CardBox>
        <header>
          <h4>Listagem de Websetup</h4>
        </header>
        <div>
          <Table<WebsetupItem>
            headers={{
              id: 'Id',
              sistema: 'Sistema',
              versao: 'Versão WebSetup',
              slug: 'Slug',
              opcoes: ''
            }}
            columnSizes={{
              id: '3rem',
              opcoes: '10rem'
            }}
            items={dados}
            customRenderers={{
              sistema: (it) => it.sistema?.nome,
              opcoes: (it) => (
                <TableButtonWrapper>
                  <Button
                    variant="secondary"
                    onClick={() => handleEdit(it as IApiWebsetupData)}
                  >
                    <FiEdit />
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => handleDelete(it as IApiWebsetupData)}
                  >
                    <FiDelete />
                  </Button>
                </TableButtonWrapper>
              )
            }}
          />
        </div>
      </CardBox>

      <Modal
        ref={modalRef}
        title={`${dadoEdit?.id ? 'Alteração' : 'Cadastro'} de Websetup`}
        onClose={handleOnCloseModal}
      >
        <WebsetupForm
          callback={() => modalRef.current?.closeModal()}
          websetup={dadoEdit}
        />
      </Modal>
    </>
  )
}

export default Websetup
