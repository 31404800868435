import { IMenuProps } from '../routes/interface'
import {
  FaFireAlt,
  FaBriefcase,
  FaDoorOpen,
  FaCloudversify,
  FaCodeBranch,
  FaChartBar
} from 'react-icons/fa'

import Main from '../pages/Main'
import Usuarios from '../modules/usuarios/pages/Usuarios'
import Clientes from '../modules/clientes/pages/Clientes'
import Logout from '../pages/Logout'
import Sistemas from '../modules/sistemas/pages/Sistemas'
import Versoes from '../modules/sistemas/pages/Versoes'
import Websetup from '../modules/websetup/pages/Websetup'
import Modulos from '../modules/websetup/pages/Modulos'
import InfoNotas from '../modules/infonotas/pages/InfoNotas'

export const getPrivateRoutesConfig = (basePath: string): IMenuProps[] => {
  return [
    {
      header: 'Dashboard',
      routes: [
        {
          text: 'Painel de Controle',
          icon: FaFireAlt,
          to: basePath,
          component: Main
        }
      ]
    },
    {
      header: 'Cadastros',
      routes: [
        {
          text: 'Cadastros',
          icon: FaBriefcase,
          to: `${basePath}/cadastros`,
          subRoutes: [
            {
              text: 'Clientes',
              to: `/clientes`,
              component: Clientes
            },
            {
              text: 'Usuários',
              to: `/usuarios`,
              component: Usuarios
            }
          ]
        }
      ]
    },
    {
      header: 'Gerenciamento',
      routes: [
        {
          text: 'Sistemas',
          icon: FaCodeBranch,
          to: `${basePath}/sistemas`,
          subRoutes: [
            {
              text: 'Cadastro',
              to: '',
              component: Sistemas,
              permission: ['ADMIN', 'DEV']
            },
            { text: 'Versões', to: '/versoes', component: Versoes }

            // {
            //   text: 'Jurídico',
            //   to: '/juridico',
            //   component: VendasJuridico,
            //   permission: ['ADMIN', 'JURIDICO']
            // },
          ]
        },
        {
          text: 'Websetup',
          icon: FaCloudversify,
          to: `${basePath}/websetup`,
          permission: ['ADMIN', 'DEV'],
          subRoutes: [
            {
              text: 'Cadastro',
              to: '',
              component: Websetup
            },
            { text: 'Modulos', to: '/modulos', component: Modulos }
          ]
        }
      ]
    },
    {
      header: 'Sistemas',
      routes: [
        {
          text: 'InfoNotas',
          icon: FaChartBar,
          to: `${basePath}/infonotas`,
          component: InfoNotas
        }
      ]
    },
    {
      header: '',
      routes: [
        {
          text: 'Sair',
          icon: FaDoorOpen,
          to: `${basePath}/sair`,
          component: Logout
        }
      ]
    }
  ]
}
