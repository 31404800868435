import { useEffect, useRef, useState } from 'react'
import { ILoadingHandles } from '../../components/Loading'
import Loading from '../../components/Loading'
import Api from '../../services/Api'
import { IApiInfoNotasResumoGeral } from '../../services/ApiRequests/InfoNotasRequests/interfaces'
import { CardHeader } from '../../styles/Card'
import * as S from './styles'

const Main = () => {
  const [infonotas, setInfoNotas] = useState<IApiInfoNotasResumoGeral>()
  const [isLoading, setLoading] = useState(false)
  const loadRef = useRef<ILoadingHandles>(null)
  // const [alugueis, setAlugueis] = useState<IApiAluguelDashboardResumo>()

  useEffect(() => {
    if (loadRef.current) loadRef.current?.showLoading()
    Api.infonotas
      .resumo()
      .then((data) => {
        setInfoNotas(data)
        loadRef.current?.hideLoading()
      })
      .catch(() => {
        loadRef.current?.hideLoading()
      })
  }, [])

  return (
    <>
      <CardHeader>
        <h1>Dashboard</h1>
      </CardHeader>
      <Loading ref={loadRef} onLoadingChange={(value) => setLoading(value)} />
      <S.DashWrapper showWrapper={!isLoading}>
        <S.DashGroup>
          <h4>InfoNotas</h4>
          <S.DashBox>
            <S.DashCard>
              <header>Pendente de Autorização</header>
              <section>{infonotas?.totalpendenteautorizacao}</section>
            </S.DashCard>
            <S.DashCard>
              <header>Pendente de Backup</header>
              <section>{infonotas?.totalpendentebackup}</section>
            </S.DashCard>
            <S.DashCard>
              <header>Certificados que irá vencer nos próximos 15 Dias</header>
              <section>{infonotas?.certvenc15dias}</section>
            </S.DashCard>
          </S.DashBox>
        </S.DashGroup>
      </S.DashWrapper>
    </>
  )
}

export default Main
